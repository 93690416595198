import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const CommunityPage = () => (
    <Layout>
        <SEO title="Community" />

        <h2 className="text-primary text-l md:text-xl md:mt-5 md:mx-5">
            Communities and free sharing of knowledge have always helped me a lot on my way so far. Through my commitment, I would like to give something back.
        </h2>
        <p className="text-primary my-3 md:my-5 md:mx-5">
            If you have any questions, want to know more about the initiatives or just want to say hi,
            send me an <a className="hover:text-primary-link bg-gray-600 rounded-md" href="mailto:hello@normade.dev?Subject=Hello" target="_top" title="Email to Norma Driske">email</a>.
            I am happy to hear from you!
        </p>

        <div className="flex flex-wrap justify-center md:px-10">

            <div className="min-w-full md:min-w-community md:max-w-community bg-custom-gray rounded-md mx-3 my-3 p-2 md:p-3">
                <a href="https://nullaufeins.org/" target="_blank" rel="noreferrer">
                    <StaticImage
                        src="../images/na1ev.png"
                        alt="nullaufeins e.V. Leipzig"
                        className="rounded-md transform hover:rotate-2"
                    />
                </a>
                <div className="pt-2 text-primary">
                    Founding member nullaufeins e.V. Leipzig
                </div>
            </div>

            <div className="min-w-full md:min-w-community md:max-w-community bg-custom-gray rounded-md mx-3 my-3 p-2 md:p-3">
                <a href="https://opentechschool.org/leipzig" target="_blank" rel="noreferrer">
                    <StaticImage
                        src="../images/ots.jpeg"
                        alt="Initiative OpenTechSchool Leipzig"
                        className="rounded-md transform hover:rotate-2"
                    />
                </a>
                <div className="pt-2 text-primary">
                    Organizer & mentor at OpenTechSchool Leipzig
                </div>
            </div>

            <div className="min-w-full md:min-w-community md:max-w-community bg-custom-gray rounded-md mx-3 my-3 p-2 md:p-3">
                <a href="https://hack-for-good.de" target="_blank" rel="noreferrer">
                    <StaticImage
                        src="../images/hackolaus_hackathon.jpg"
                        alt="Iniative Hack for Good Leipzig"
                        className="rounded-md transform hover:rotate-2"
                    />
                </a>
                <div className="pt-2 text-primary">
                    Initiator & organizer Hack for Good Leipzig
                </div>
            </div>

            <div className="min-w-full md:min-w-community md:max-w-community bg-custom-gray rounded-md mx-3 my-3 p-2 md:p-3">
                <a href="https://hackerinnen.space" target="_blank" rel="noreferrer">
                    <StaticImage
                        src="../images/hackerinnen.jpeg"
                        alt="Initiative hackerinnen.space"
                        className="rounded-md transform hover:rotate-2"
                    />
                </a>
                <div className="pt-2 text-primary">
                    With <a class="hover:text-primary-link bg-gray-600 rounded-md" href="http://kristin-fritsch.de" target="_blank" rel="noreferrer">Kristin Fritsch</a> initiator of hackerinnen.space
                </div>

            </div>

            <div className="min-w-full md:min-w-community md:max-w-community bg-custom-gray rounded-md mx-3 my-3 p-2 md:p-3">
                <a href="https://djangogirls.org" target="_blank" rel="noreferrer">
                    <StaticImage
                        src="../images/djangogirls.jpeg"
                        alt="Iniative Django Girls"
                        className="rounded-md transform hover:rotate-2"
                    />
                </a>
                <div className="pt-2 text-primary">
                    Organizer & mentor at Django Girls Leipzig, Dresden, Heidelberg, Basel, Belgrade
                </div>
            </div>

            <div className="min-w-full md:min-w-community md:max-w-community bg-custom-gray rounded-md mx-3 my-3 p-2 md:p-3">
                <a href="https://jugendhackt.org" target="_blank" rel="noreferrer">
                    <StaticImage
                        src="../images/jugendhackt.jpeg"
                        alt="Initiative Jugend Hackt"
                        className="rounded-md transform hover:rotate-2"
                    />
                </a>
                <div className="pt-2 text-primary">
                    Mentor at jugendhackt.org
                </div>
            </div>

            <div className="min-w-full md:min-w-community md:max-w-community bg-custom-gray rounded-md mx-3 my-3 p-2 md:p-3">
                <a href="https://django-verein.de" target="_blank" rel="noreferrer">
                    <StaticImage
                        src="../images/ddv.png"
                        alt="Logo Deutscher Django Verein"
                        className="rounded-md transform hover:rotate-2"
                    />
                </a>
                <div className="pt-2 text-primary">
                    Member of board, German Django Association
                </div>
            </div>

        </div>

    </Layout >
)

export default CommunityPage